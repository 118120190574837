import "./Newsletter.scss";

import React, { useState } from "react";

import ArrowRight from "../../../img/arrow-right.svg";
import MailchimpSubscribe from "react-mailchimp-subscribe";

const Newsletter = () => {
  const [email, setEmail] = useState("");

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e, subscribe) => {
    e.preventDefault();
    if (email && email.indexOf("@") > -1) {
      subscribe({ EMAIL: email });
    } else {
      console.log("Email non valide ou vide.");
    }
  };

  return (
    <section className="Newsletter">
      <h3>
        Newsletter
        <span className="copy">Newsletter</span>
      </h3>
      <p>Abonnez-vous pour découvrir nos prochains posts</p>
      <MailchimpSubscribe
        url={process.env.GATSBY_MAILCHIMP_URL}
        render={({ subscribe, status, message }) => (
          <div>
            <form
              name="newsletter"
              method="post"
              onSubmit={(e) => handleSubmit(e, subscribe)}
            >
              <input
                className="input"
                type="email"
                name="email"
                required={true}
                placeholder="VOTRE ADRESSE EMAIL"
                onChange={(e) => handleEmail(e)}
              />
              <button
                className="button"
                type="submit"
                disabled={status === "sending"}
                style={{ opacity: status === "sending" ? 0.5 : 1 }}
              >
                <img src={ArrowRight} alt="" />
              </button>
            </form>
            {status === "error" &&
              !!!console.log(
                "Erreur lors de l'envoi du formulaire: ",
                message
              ) && (
                <p className="error">
                  Une erreur est survenue lors de l'envoi du formulaire.
                </p>
              )}
            {status === "success" && (
              <p className="confirmation">
                Votre inscription à la newsletter a bien été prise en compte.
              </p>
            )}
          </div>
        )}
      />
    </section>
  );
};

export default Newsletter;
